import { render, staticRenderFns } from "./uLife.vue?vue&type=template&id=1040f820&scoped=true"
import script from "./uLife.vue?vue&type=script&lang=js"
export * from "./uLife.vue?vue&type=script&lang=js"
import style0 from "./uLife.vue?vue&type=style&index=0&id=1040f820&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1040f820",
  null
  
)

export default component.exports