<template>
  <div class="container">

    <img
      class="top"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/selectMembers_guangtong/uLife/selectmember_top.png"
    />

    <div class="form">
      <van-field
        v-model="form.phone_number"
        type="tel"
        maxlength="11"
        placeholder="请输入您的手机号码"
      />
      <img
        class="submit"
        @click="onSubmit"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/selectMembers_guangtong/selectmember_submit.png"
      />

      <div class="checked">
        <van-checkbox
          checked-color="#ffd614"
          v-model="checked"
          icon-size="14px"
        >
          &nbsp;我已阅读并同意<span class="book" @click="dialogInfo"
            >《服务协议》</span
          >
        </van-checkbox>
      </div>
    </div>

    <img
      class="bg"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/selectMembers_guangtong/uLife/selectmember_bg02.png"
    />

    <div class="title">
        ——————&nbsp; <span> 订购须知 </span> &nbsp;——————
    </div>
    <div class="info">
      <div class="text">1、本页面仅供客户购买权益，老年及未成年人请谨慎消费，敬请知悉！</div>
      <div class="text">2、为了您的权益保障，请注意核实订购规则及订单详情，不要轻信陌生人通过网络、电话、短信告知的信息，谨防网络电信诈骗</div>
      <div class="text">【商品名称】：优生活会员</div>
      <div class="text">【计费方式】：支付宝连续包月</div>
      <div class="text">【资费】：首月29.9元，次月起49.9元/月</div>
      <div class="text">【商品权益说明】：热门会员权益*2+10元支付宝生活缴费券*2+10元超市优惠券(天猫超市/盒马生鲜/永辉超市,三选一)*3</div>
      <div class="text">【会员使用说明】：视听会员权益如腾讯爱奇艺通过订购手机号登陆对应平台即可使用</div>
      <div class="text">【10元生活缴费券使用说明】：</div>
      <div class="text">
        1、红包自用户领取起30天内有效，红包不可拆分不得提现、无法转赠、不得为他人付款，过期自动作废，无法使用，同时也不予补发。
      </div>
      <div class="text">
        2、该红包可用场景为生活缴费，使用支付宝选择指定银行及卡类型且单笔实际支付金额大于或者等于100元时可抵扣使用(实际使用范围以红包可抵扣为准)使用红包的订单若发生退款，订单可退金额及红包可退面额以消费者的退款页面信息为准。退回的红包若在有效期内则可继续使用，且使用规则不变，退回的红包若已过有效期则无法使用，且不予补发。
      </div>
      <div class="text">
        3、支付宝端的指定银行及卡类型活动，仅限中华人民共和国境内的支付宝实名认证用户参与;同一用户活动期内每月可领取2次。同一支付宝账号手机号、身份证、手机终端，符合以上任一条件均视为同一用户。
      </div>
      <div class="text">【10元天猫超市优惠券使用说明】：</div>
      <div class="text">
        1、红包自用户领取起30天内有效，红包不可拆分不得提现、无法转赠、不得为他人付款，过期自动作废，无法使用，同时也不予补发。
      </div>
      <div class="text">
        2、该红包可用场景为天猫超市，使用支付宝选择指定银行及卡类型且单笔实际支付金额大于或者等于200元时可抵扣使用(实际使用范围以红包可抵扣为准)使用红包的订单若发生退款，订单可退金额及红包可退面额以消费者的退款页面信息为准。退回的红包若在有效期内则可继续使用，且使用规则不变，退回的红包若已过有效期则无法使用，且不予补发。
      </div>
      <div class="text">
        3、支付宝端的指定银行及卡类型活动，仅限中华人民共和国境内的支付宝实名认证用户参与;同一用户活动期内每月可领取3次。同一支付宝账号手机号、身份证、手机终端，符合以上任一条件均视为同一用户。
      </div>
      <div class="text">【10元盒马生鲜优惠券使用说明】：</div>
      <div class="text">
        1、红包自用户领取起30天内有效，红包不可拆分不得提现、无法转赠、不得为他人付款，过期自动作废，无法使用，同时也不予补发。
      </div>
      <div class="text">
        2、该红包可用场景为盒马生鲜，使用支付宝选择指定银行及卡类型且单笔实际支付金额大于或者等于200元时可抵扣使用(实际使用范围以红包可抵扣为准)使用红包的订单若发生退款，订单可退金额及红包可退面额以消费者的退款页面信息为准。退回的红包若在有效期内则可继续使用，且使用规则不变，退回的红包若已过有效期则无法使用，且不予补发。
      </div>
      <div class="text">
        3、支付宝端的指定银行及卡类型活动，仅限中华人民共和国境内的支付宝实名认证用户参与;同一用户活动期内每月可领取3次。同一支付宝账号手机号、身份证、手机终端，符合以上任一条件均视为同一用户。
      </div>
      <div class="text">【10元永辉超市优惠券使用说明】：</div>
      <div class="text">
        1、红包自用户领取起30天内有效，红包不可拆分不得提现、无法转赠、不得为他人付款，过期自动作废，无法使用，同时也不予补发。
      </div>
      <div class="text">
        2、该红包可用场景为永辉超市，使用支付宝选择指定银行及卡类型且单笔实际支付金额大于或者等于200元时可抵扣使用(实际使用范围以红包可抵扣为准)使用红包的订单若发生退款，订单可退金额及红包可退面额以消费者的退款页面信息为准。退回的红包若在有效期内则可继续使用，且使用规则不变，退回的红包若已过有效期则无法使用，且不予补发。
      </div>
      <div class="text">
        3、支付宝端的指定银行及卡类型活动，仅限中华人民共和国境内的支付宝实名认证用户参与;同一用户活动期内每月可领取3次。同一支付宝账号手机号、身份证、手机终端，符合以上任一条件均视为同一用户。
      </div>
    </div>
    <img
      class="bottom"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/selectMembers_guangtong/yifen/yifen_bg02.png"
    />
    <img
      class="bottom"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/selectMembers_guangtong/yifen/yifen_bg03.png"
    />
    <v-dialog :visibility.sync="visibility" :aitosuper= true />
  </div>
</template>

<script>
import { alipayPayNoCode } from "@/api/guangtong_equity_center.js";
import VDialog from "./dialog/index.vue";
export default {
  data() {
    return {
      form: {
        phone_number: "",
      },
      sendAuthCode: true,
      checked: false,
      auth_time: 0,
      state: false,
      visibility: false,
    };
  },
  components: {
    VDialog,
  },
  mounted() {
  const metaTag = document.createElement("meta");
    metaTag.name = "referrer";
    metaTag.content = "no-referrer";
   document.getElementsByTagName("head")[0].appendChild(metaTag);
   },
   beforeDestroy() {
     const metaTag = document.querySelector('meta[name="referrer"]');
     if (metaTag) {
       document.getElementsByTagName("head")[0].removeChild(metaTag);
    }
   },
  methods: {
    async onSubmit() {
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone_number } = this.form;
      if (!filterPhone.test(phone_number)) {
        this.$toast.fail("手机号码有误请重新输入");
      } else if (!this.checked) {
        this.$toast.fail("请勾选“我已经阅读并同意《服务协议》”");
      } else {
        this.$toast.loading({
          duration: 0,
          forbidClick: true,
          mask: true,
          message: "加载中...",
        });
        const res = await alipayPayNoCode({
          phone_number,
          item_name: "优生活会员",
        });
        this.$toast.clear();
        if (res.code != 0) {
          this.$toast({
            duration: 8000,
            message: res.message,
          });
        } else {
          location.href = `alipays://platformapi/startapp?appId=20000067&url=${encodeURIComponent(
            res.sign_str
          )}`;
        }
      }
    },
    dialogInfo() {
      this.visibility = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #8D9DFD;
  padding-bottom: 20px;
  .top,
  .card {
    width: 100%;
  }
  .bg{
    width: 94vw;
    margin: 0 3vw;
  }

  .form {
    height: 344px;
    width: 750px;
    padding-top: 33px;
    box-sizing: border-box;

    ::v-deep .van-cell {
      width: 654px;
      height: 90px !important;
      background-color: #ffffff;
      border-radius: 50px;
      margin: 0px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 23px;

      &.input-code {
        margin-top: 20px;

        .code {
          width: 190px;
          height: 100px;
          text-align: center;
          line-height: 100px;
          color: #6D48D8;
          font-size: 28px;
          font-weight: normal;
          background-color: #DEDCFF;
        }
      }

      .van-field__body {
        height: 100%;

        input {
          height: 100%;
        }
      }

      &::after {
        border: none;
      }

      .van-field__control {
        color: #000;
        font-weight: normal;
        &::-webkit-input-placeholder {
          font-size: 34px;
          font-weight: 40;
        }
      }
    }

    .submit {
      width: 654px;
      height: 90px;
      display: block;
      margin: 36px auto 16px;
    }
    .price{
      width: 100%;
      height: 40px;
      margin: 10px 0 0;
      text-align: center;
      color: #fff;
      font-size: 28px;
    }

    .checked {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 24px;
      font-weight: 400;
      padding: 10px 0;

      ::v-deep .van-checkbox {
        width: auto;
        .van-checkbox__icon .van-icon{
          border: 1px solid #fff;
        }

        &__label {
          margin-left: 4px;
          color: #fff !important;

          .book {
            color: #FEED72;
          }
        }
      }
    }
  }
  .title {
    width: 80vw;
    height: 60px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    text-align: center;
    line-height: 60px;

    margin: 60px auto 0;
    color: #fff;
    span{
      font-size: 34px;
      font-weight: bold;
    }
  }
  .info {
    width: 96vw;
    border-radius: 20px;
    margin: 0 auto;
    box-sizing: border-box;
    font-size: 24px;
    font-weight: 500;
    padding: 20px 40px 50px;
    line-height: 40px;
    color: #fff;

    .text {
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 36px;
      letter-spacing: 0px;
    }
  }
  .bottom{
    width: 94vw;
    margin: 10px 3vw;
  }
}
</style>
